import React from "react";
import { useTranslation } from "react-i18next";

const CtaV13 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const { t } = useTranslation();
  return (
    <section className="go-top cta-v13" style={{ padding: "5rem 0" }}>
      <div className="container cta-five">
        <div className="d-flex">
          <div className="my-auto">
            <div className="cta-five__content">
              <h3>{t("home.section2.title")}</h3>
              <p>{t("home.section2.subtitle")}</p>
              <div>
                <a href="/service/" className="thm-btn mt-4">
                  {t("home.section2.button")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaV13;
