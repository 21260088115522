import React from "react";
import { useTranslation } from "react-i18next";

const CtaV14 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation();
  return (
    <section className="container cta-five" style={{ padding: "5rem 0 3rem 1rem" }}>
      <h3>{t("home.section3.title")}</h3>
      <p>{t("home.section3.subtitle")}</p>
    </section>
  );
};

export default CtaV14;
