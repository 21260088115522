import React from "react";
import { useTranslation } from "react-i18next";

const FunfactV5 = () => {
  const { t } = useTranslation();
  return (
    <section className="cta-portada text-center py-5">
      <div className="funfact-one__single">
        <h3 className="text-light cta-titulo">{t("home.banner.title")}</h3>
        <p className="text-light cta-descripcion">
          {t("home.banner.subtitle")}
        </p>
        <div className="cta-four__btn-block ">
          <a href="/service/" className=" btn-cta-portada">
            {t("home.banner.button")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default FunfactV5;
