import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

function Banner() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      setEmail(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Ingrese su correo electrónico");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Ingrese un correo electrónico válido");
      return;
    }
    const state = { emailRegister: email };
    history.push({ pathname: "/register", search: "", hash: "", state });
  };

  return (
    <section className="banner-one go-top">
      <div className="overlay">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="banner-one__content">
                <h3>
                  {t("home.title")}
                  <br /> {t("home.title2")}
                </h3>
                <p>{t("home.subtitle")}</p>
                <form className="form_portada" onSubmit={handleSubmit}>
                  <div
                    className="d-flex flex-column flex-sm-row justify-content-center mx-auto p-2"
                    style={{ maxWidth: "550px", gap: "1rem 1.5rem" }}
                  >
                    {/* email */}
                    <input
                      type="email"
                      id="email"
                      value={email || ""}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Email"
                      required
                    />

                    {/* button */}
                    <div className="">
                      <button
                        type="submit"
                        className="thm-btn d-flex align-items-center mx-auto"
                      >
                        {t("home.button")}
                        <i className="fa fa-angle-double-right ms-2" />
                      </button>
                    </div>
                  </div>
                  {error && <p>{error}</p>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
