import React from 'react';
import Navbar from './global-components/navbar-color';
import Contact from './section-components/contact-page';
import Footer from './global-components/footer';

const ContactPage = () => {
    return <div className="page-container">
        <Navbar />
        <Contact />
        <Footer />
    </div>
}

export default ContactPage

