import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const lngs = {
  es: { nativeName: "ES" },
  en: { nativeName: "EN" },
  ca: { nativeName: "CA" },
};

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <header className={`site-header-one go-top ${scrolled ? "scrolled" : ""}`}>
      <nav className="main-nav__one stricky">
        <div className="container-fluid">
          <div className="main-nav__logo-box">
            <Link to="/">
              <h1 className="text-light header-logo my-auto">SIMOONS</h1>
            </Link>
            <a href="#" className="side-menu__toggler">
              <i className="fa fa-bars" />
            </a>
          </div>
          <div className="main-nav__main-navigation">
            <ul className="main-nav__navigation-box">
              <li>
                <Link to="/">{t("header.menu.inicio")}</Link>
              </li>
              <li>
                <Link to="/service">{t("header.menu.entornos")}</Link>
              </li>
              <li>
                <Link to="/team">{t("header.menu.equipo")}</Link>
              </li>
              <li>
                <Link to="/categories">Blog</Link>
              </li>
              <li>
                <Link to="/faq">FAQS</Link>
              </li>
              <li>
                <Link to="/contact">{t("header.menu.contacto")}</Link>
              </li>
              <li className="mt-5 d-xl-none">
                <a
                  href="https://admin.simoonsvirtual.cat/"
                  className="thm-btn text-white mt-4"
                >
                  {t("header.buttons.acceder")} <i className="fa fa-user" />
                </a>
              </li>
              <li className="d-xl-none">
                <a
                  href="https://admin.simoonsvirtual.cat/#/auth/register"
                  className="thm-btn text-white mt-4"
                >
                  {t("header.buttons.registrarse")}{" "}
                  <i className="fa fa-angle-right" />
                </a>
              </li>
            </ul>
          </div>
          <div className="boxlang">
            {Object.keys(lngs).map((lng) => (
              <button
                className="idiomas"
                key={lng}
                style={{
                  fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
                }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName}
              </button>
            ))}
          </div>
          <div className="main-nav__right d-none d-sm-none d-lg-block">
            <a
              href="https://admin.simoonsvirtual.cat/"
              className="thm-btn mr-2 "
            >
              {t("header.buttons.acceder")} <i className="fa fa-user" />
            </a>
            <a
              href="https://admin.simoonsvirtual.cat/#/auth/register"
              className="thm-btn"
            >
              {t("header.buttons.registrarse")}{" "}
              <i className="fa fa-angle-right" />
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
