import React from "react";
import { useTranslation } from "react-i18next";

const CtaV15 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const { t } = useTranslation();

  return (
    <section className="cta-five cta-v13 py-5">
      <div className="container">
        <div
          className="d-flex align-items-center flex-column-reverse flex-lg-row gap-5"
          style={{ gap: "2rem" }}
        >
          {/* image */}
          <div className="cta-v13__image">
            <img
              src={
                publicUrl +
                "assets/images/resources/making-of-video-corporatiu-2023.jpg"
              }
              alt={imagealt}
              maxWidth="500"
              width="100%"
            />
          </div>

          {/* content */}
          <div className="d-flex flex-column justify-content-center">
            <h3>{t("home.section4.title")}</h3>
            <p>{t("home.section4.subtitle")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaV15;
